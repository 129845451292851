@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,600&display=swap');

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.one-third::before {
  content: '';
  flex: 1;
}

.one-third::after {
  content: '';
  flex: 3;
}

/* Scrollbar settings */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* Flip card for experience section */
.flip-outer {
  text-align: center;
  perspective: 600px;  
}
.flip-card {
  transition: transform 0.4s;
  -webkit-transition: -webkit-transform 0.4s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.flip-outer:hover .flip-card {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -webkit-transition: transform 0.3s;
}

.flip-front, .flip-back {
  position: absolute;
  backface-visibility: hidden;  
  -webkit-backface-visibility: hidden;  
}
.flip-back {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

/* Header underlines */
nav a {
    display: inline-block;
    position: relative;
}

nav a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

nav a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.wrapper{
  display: flex;
}

.wrapper .dynamic-txts{
  height: 45px;
  line-height: 45px;
  overflow: hidden;
}
.dynamic-txts li{
  list-style: none;
  position: relative;
  top: 0;
  animation: slide 9s steps(3) infinite;
}
@keyframes slide {
  100%{
    top: -135px;
  }
}
.dynamic-txts li span{
  position: relative;
  line-height: 45px;
}
.dynamic-txts li span::after{
  top: 0.25px;
  content: "";
  position: absolute;
  left: 0;
  height: 110%;
  width: 100%;
  background: white;
  border-left: 2px solid #FC6D6D;
  animation: typing 3s steps(16) infinite;
}
@keyframes typing {
  40%, 60%{
    left: calc(100%);
  }
  100%{
    left: 0;
  }
}


@supports (-webkit-text-size-adjust:none) and (not (-ms-ime-align:auto))
and (not (-moz-appearance:none))
{

  .safari-only {
      aspect-ratio: 1.1 / 1;
  }

}